.templates_timeRecordings_recordPropertiesForm_recordPropertiesForm > .cols > .col .field {
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
}
.templates_timeRecordings_recordPropertiesForm_recordPropertiesForm > .cols > .col .field > label:not(.fieldIndependent),
.templates_timeRecordings_recordPropertiesForm_recordPropertiesForm > .cols > .col .field .base_form_fields_period label {
  width: 100% !important;
  transform: unset;
}
.templates_timeRecordings_recordPropertiesForm_recordPropertiesForm > .cols > .col .field > *:not(.fieldIndependent) {
  width: 100% !important;
}
.base_modal_modal .window section.body > .base_form_form.templates_timeRecordings_recordPropertiesForm_recordPropertiesForm {
  padding: 10px;
}
.base_modal_modal.container_base_form_form.container_templates_timeRecordings_recordPropertiesForm_recordPropertiesForm .window {
  width: 600px;
  height: auto;
  top: calc(50% - 200px);
  left: 200px;
}
body.mobile .base_modal_modal .window section.body > .base_form_form.templates_timeRecordings_recordPropertiesForm_recordPropertiesForm {
  font-size: 0.75em;
}
body.mobile .base_modal_modal.container_base_form_form.container_templates_timeRecordings_recordPropertiesForm_recordPropertiesForm .window {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  top: 15px;
  left: 10px;
}
body.mobile .base_modal_modal.container_base_form_form.container_templates_timeRecordings_recordPropertiesForm_recordPropertiesForm .window .footer {
  font-size: 0.75em;
}
