.templates_controller_page {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}
.templates_controller_page section.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  height: 10vh;
  max-height: 10vh;
  color: #FFFFFF;
  background-color: #333333;
  border-bottom: 1px solid #FFFFFF;
}
.templates_controller_page section.head img {
  max-height: 8vh;
}
.templates_controller_page section.head button {
  background-color: transparent;
  border: none;
  color: inherit;
}
.templates_controller_page section.head button:hover {
  cursor: pointer;
}
.templates_controller_page section.head button:active,
.templates_controller_page section.head button:focus {
  border: none;
  outline: none;
}
.templates_controller_page section.head .left,
.templates_controller_page section.head .center,
.templates_controller_page section.head .right {
  display: flex;
}
.templates_controller_page section.head .left {
  width: 50%;
  padding-left: 1vh;
  box-sizing: border-box;
}
.templates_controller_page section.head .left button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 2vh;
}
.templates_controller_page section.head .left button i {
  font-size: 6vh;
  margin-right: 2vh;
}
.templates_controller_page section.head .center {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.templates_controller_page section.head .center .title {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.templates_controller_page section.head .right {
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
}
.templates_controller_page section.head .right button {
  font-size: 6vh;
  padding: 2vh;
}
.templates_controller_page section.head .right button i {
  margin: 0;
}
.templates_controller_page section.top {
  width: 100%;
  font-size: 0.875em;
  font-style: italic;
  height: 3vh;
  padding: 0.75vh 3vh 0 3vh;
  box-sizing: border-box;
}
.templates_controller_page section.content {
  display: inline-block;
  width: 100vw;
  height: 87vh;
}
body.mobile .templates_controller_page {
  height: 90vh;
}
body.mobile .templates_controller_page section.content {
  height: 77vh;
}
