.templates_shippingOrder_sign_sign {
  width: 500px;
  max-width: 100%;
  padding-top: 2em;
}
.templates_shippingOrder_sign_sign > .background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0.8;
  display: none;
  z-index: 1;
}
.templates_shippingOrder_sign_sign > .controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5em;
}
.templates_shippingOrder_sign_sign > .controls > button {
  font-size: 2em;
  padding: 0.25em 0.5em;
  background-color: #2e6ab1;
  border: 1px solid #163f70;
  border-radius: 0.25em;
  color: #FFFFFF;
}
.templates_shippingOrder_sign_sign > .controls > button > .shrink {
  display: none;
}
.templates_shippingOrder_sign_sign > .controls > button > .enlarge {
  display: block;
}
.templates_shippingOrder_sign_sign > .controls > button.save {
  background-color: #006600;
  border-color: #006600;
}
.templates_shippingOrder_sign_sign > .sign {
  width: 500px;
  max-width: 100%;
  z-index: 2;
}
.templates_shippingOrder_sign_sign > .sign > canvas {
  border: 1px dotted #CDCDCD;
  background-color: #FAFAFA;
}
.templates_shippingOrder_sign_sign > .sign > img {
  width: 100%;
}
.templates_shippingOrder_sign_sign > .subTitle {
  margin-top: 0.5em;
}
.templates_shippingOrder_sign_sign > .subTitle > .signedAt {
  font-style: italic;
  font-size: 0.75em;
}
.templates_shippingOrder_sign_sign.enlarged {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0.75em;
  box-sizing: border-box;
  z-index: 1;
}
.templates_shippingOrder_sign_sign.enlarged > .background {
  display: block;
  z-index: 1;
}
.templates_shippingOrder_sign_sign.enlarged > .controls {
  z-index: 2;
  position: relative;
}
.templates_shippingOrder_sign_sign.enlarged > .controls > button {
  box-shadow: 0 0 0.375em #FFFFFF;
}
.templates_shippingOrder_sign_sign.enlarged > .controls > button > .shrink {
  display: block;
}
.templates_shippingOrder_sign_sign.enlarged > .controls > button > .enlarge {
  display: none;
}
.templates_shippingOrder_sign_sign.enlarged > .sign {
  position: fixed;
  width: 80%;
  height: 80%;
  top: 15%;
  left: 10%;
  z-index: 2;
}
.templates_shippingOrder_sign_sign.enlarged .subTitle {
  display: none;
}
body.mobile .templates_shippingOrder_sign_sign > .subTitle {
  font-size: 1.125em;
}
