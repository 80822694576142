.base_modal_modal.container_errors_errors {
  z-index: 900000;
}
.base_modal_modal.container_errors_errors .background {
  background-color: #F5F5F5;
}
.base_modal_modal.container_errors_errors .window {
  width: 50%;
  height: 50%;
  top: 15%;
  left: 25%;
}
.base_modal_modal.container_errors_errors .window * {
  border-color: #333333;
  background-color: #F5F5F5;
  color: #333333;
}
.base_modal_modal.container_errors_errors .window section.header * {
  color: #333333;
}
.base_modal_modal.container_errors_errors .window section.footer button:hover {
  background-color: #333333;
  color: #F5F5F5;
  border-color: #F5F5F5;
}
.errors_errors {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 4vh;
}
.errors_errors > * {
  float: left;
}
.errors_errors .image {
  height: 100%;
  width: 40%;
  margin-right: 4vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}
.errors_errors .content {
  width: calc(60% - 9vh);
}
.errors_errors .content h1 {
  margin: 0 0 1vh 0;
  font-size: 3vh;
}
.errors_errors .content h2 {
  margin-top: 0;
  margin-bottom: 2vh;
  font-size: 2vh;
}
body.mobile .errors_errors {
  padding: 2vh;
}
body.mobile .errors_errors > div {
  width: 100%;
  margin: 0;
}
body.mobile .errors_errors > div.image {
  height: 25%;
  background-size: contain;
}
body.breakPoint-m .base_modal_modal.container_errors_errors .window {
  width: 96%;
  height: 60%;
  top: 10%;
  left: 2%;
}
body.mobile.breakPoint-m .base_modal_modal.container_errors_errors .errors_errors .image {
  display: none;
}
body.mobile.breakPoint-m .base_modal_modal.container_errors_errors .errors_errors .content {
  width: 100%;
}
body.error_container > .errors_errors {
  position: relative;
  width: 70%;
  height: 50%;
  top: 10%;
  left: 15%;
}
@media screen and (max-width: 1024px) {
  body.error_container > .errors_errors {
    position: relative;
    width: 96%;
    height: 60%;
    top: 10%;
    left: 2%;
  }
}
