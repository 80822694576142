.templates_controller_element {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.templates_controller_element > section {
  display: block;
}
.templates_controller_element > section.center {
  width: 100%;
  height: 100%;
  padding: 0 3vh 3vh 3vh;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
}
body.mobile .templates_controller_element > section.center {
  padding: 2vh;
  overflow: visible;
}
