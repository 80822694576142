.templates_calendar_scheduler_scheduler .header .nav button {
  margin: 0;
  font-size: 4vh;
  border: 1px solid #333333;
  padding: 0.75vh 1.5vh;
  height: 6vh;
}
.templates_calendar_scheduler_scheduler .header .nav .nav-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5vh;
}
.templates_calendar_scheduler_scheduler .header .nav .nav-buttons .title {
  font-size: 3vh;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_calendar_scheduler_scheduler .header .col-heads div div {
  width: 100%;
  padding: 1vh;
  height: 4.5vh;
  font-size: 2vh;
  box-sizing: border-box;
}
.templates_calendar_scheduler_scheduler .month-wrapper,
.templates_calendar_scheduler_scheduler .week-wrapper,
.templates_calendar_scheduler_scheduler .day-wrapper {
  max-height: 73vh;
}
.templates_calendar_scheduler_scheduler .month-wrapper .day,
.templates_calendar_scheduler_scheduler .week-wrapper .day,
.templates_calendar_scheduler_scheduler .day-wrapper .day {
  height: 12vh;
}
.templates_calendar_scheduler_scheduler .month-wrapper .day > div.content,
.templates_calendar_scheduler_scheduler .week-wrapper .day > div.content,
.templates_calendar_scheduler_scheduler .day-wrapper .day > div.content {
  padding-top: 0;
}
.templates_calendar_scheduler_scheduler .base_scheduler_task {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 2vh;
  border-radius: 0.5em;
}
body.mobile .templates_calendar_scheduler_scheduler .header .nav {
  justify-content: flex-start;
  align-items: center;
}
body.mobile .templates_calendar_scheduler_scheduler .header .nav .title {
  color: #333333;
}
body.mobile .templates_calendar_scheduler_scheduler .header .nav .title .kw {
  display: inline-flex;
}
body.mobile .templates_calendar_scheduler_scheduler .header .nav .title .seperator {
  display: inline-flex;
}
body.mobile .templates_calendar_scheduler_scheduler .header .col-heads div div {
  font-size: 1.75vh;
  height: 2.5vh;
  padding: 0.5vh;
  justify-content: center;
}
body.mobile .templates_calendar_scheduler_scheduler .month-wrapper,
body.mobile .templates_calendar_scheduler_scheduler .week-wrapper,
body.mobile .templates_calendar_scheduler_scheduler .day-wrapper {
  max-height: 65vh;
}
body.mobile .templates_calendar_scheduler_scheduler .month-wrapper .day,
body.mobile .templates_calendar_scheduler_scheduler .week-wrapper .day,
body.mobile .templates_calendar_scheduler_scheduler .day-wrapper .day {
  height: 11vh;
}
body.mobile .templates_calendar_scheduler_scheduler .month-wrapper .day > div,
body.mobile .templates_calendar_scheduler_scheduler .week-wrapper .day > div,
body.mobile .templates_calendar_scheduler_scheduler .day-wrapper .day > div {
  padding: 0.5vh;
  width: calc(100% - 1vh);
}
body.mobile .templates_calendar_scheduler_scheduler .month-wrapper .day > div.content,
body.mobile .templates_calendar_scheduler_scheduler .week-wrapper .day > div.content,
body.mobile .templates_calendar_scheduler_scheduler .day-wrapper .day > div.content {
  padding-top: 0;
}
body.mobile .base_scheduler_task {
  padding: 0.5vh;
  -ms-word-break: break-all;
  word-break: break-all;
}
