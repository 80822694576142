.base_tableSimple_table.templates_timeRecordings_table_table.templates_shippingOrder_table_table {
  margin-bottom: 3em;
  border-top: none;
}
.base_tableSimple_table.templates_timeRecordings_table_table.templates_shippingOrder_table_table > table > tbody:hover {
  background-color: unset;
  color: unset;
  cursor: text;
}
.base_tableSimple_table.templates_timeRecordings_table_table.templates_shippingOrder_table_table > table > tbody > tr > td,
.base_tableSimple_table.templates_timeRecordings_table_table.templates_shippingOrder_table_table > table > thead > tr > td {
  overflow: hidden;
}
