.templates_project_project {
  font-size: 2em;
  color: #333333;
  display: flex;
  flex-direction: column;
  padding-bottom: 2vh;
}
.templates_project_project i {
  margin-right: 0.25em;
}
.templates_project_project button {
  font-size: 0.75em;
  font-weight: bold;
  padding: 0.375em;
}
.templates_project_project button i {
  margin-right: 0.25em;
  width: unset;
}
.templates_project_project button i.ifTimeRegistrationDone {
  display: none;
}
.templates_project_project button.active {
  background-color: #2e6ab1;
  color: #FFFFFF;
  border-color: #333333;
}
.templates_project_project button.acknowledge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_project_project button.acknowledge .content {
  display: none;
}
.templates_project_project button.acknowledge .content.acknowledge {
  display: flex;
}
.templates_project_project button.acknowledge i {
  display: none;
  margin-right: 1.8vh;
}
.templates_project_project button.acknowledge.acknowledged {
  background-color: #2c8933;
  color: #FFFFFF;
  border-color: #333333;
}
.templates_project_project button.acknowledge.acknowledged:hover {
  cursor: default;
}
.templates_project_project button.acknowledge.acknowledged i.acknowledged {
  display: flex;
}
.templates_project_project button.acknowledge.acknowledged .content {
  display: none;
}
.templates_project_project button.acknowledge.acknowledged .content.acknowledged {
  display: flex;
}
.templates_project_project button.acknowledge.pending i.pending {
  display: flex;
}
.templates_project_project button.acknowledge.error i.error {
  display: flex;
}
.templates_project_project button.timeRegistrationDone i.ifTimeRegistrationDone {
  display: unset;
}
.templates_project_project button i {
  font-size: 1em;
}
.templates_project_project ul {
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
}
.templates_project_project ul li {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.125rem;
}
.templates_project_project ul li.head {
  font-size: 1.5em;
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #333333;
}
.templates_project_project ul li > div {
  text-align: right;
  width: 46%;
  margin-right: 4%;
}
.templates_project_project ul li > div.content {
  width: 50%;
  margin-right: 0;
  text-align: left;
}
.templates_project_project ul li.buttons {
  margin-top: 0.25rem;
  border-top: 1px solid #333333;
  font-size: 1em;
  justify-content: flex-start;
}
.templates_project_project ul li.buttons .info {
  font-size: 2vh;
  text-align: left;
  width: auto;
  margin: 0 auto 0 0;
}
.templates_project_project ul li.buttons button {
  margin: 0.5em 0 0 0.5em;
  border-radius: 0;
  font-weight: normal;
}
.templates_project_project ul.navigation {
  margin: 0 auto 0.75em;
  width: 100% !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.templates_project_project ul.navigation li {
  flex: 1 1 auto;
  margin: 0 0 0.25em 0;
  justify-content: center;
}
.templates_project_project ul.navigation li button {
  margin: 0;
}
.templates_project_project ul.navigation li button.timeRegistrationDone {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}
.templates_project_project ul.navigation li button.timeRegistrationDone i.ifTimeRegistrationDone {
  color: #006400;
  font-size: 0.75em;
  position: static;
  transform: translate(0, 1em);
  background-color: #FFFFFF;
  border-radius: 1em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  margin: 0 0 0 -1em;
}
.templates_project_project ul.navigation li:last-of-type {
  margin-right: 0;
}
.templates_project_project ul.projectPage:not(.active) {
  display: none;
}
.templates_project_project ul.projectPage > li.buttons > button.timeRegistrationDone {
  background-color: #2c8933;
  color: #FFFFFF;
  border-color: #333333;
}
.templates_project_project ul.projectPage > li.buttons > button.timeRegistrationDone > i:last-child {
  margin-right: 0;
  margin-left: 0.25em;
}
.templates_project_project ul.projectMeta li.head button {
  float: right;
  height: 100%;
  padding: 0 0.375em;
}
.templates_project_project ul.projectMeta li.head > .content {
  width: 100%;
}
.templates_project_project ul.projectMeta li.data {
  background-color: #EEEEEE;
  border: 1px solid #999999;
  border-radius: 0.25em;
  margin-bottom: 0.25em;
  word-break: keep-all;
  white-space: nowrap;
}
.templates_project_project ul.projectMeta li.data.signed {
  background-color: #2c8933;
  color: #FFFFFF;
  border-color: #333333;
}
.templates_project_project ul.projectMeta li.data .right {
  float: right;
}
.templates_project_project ul.projectMeta li.data .subTitle {
  font-size: 0.75em;
}
.templates_project_project ul.projectMeta li.data a {
  padding: 0.25em 0.5em;
  width: 100%;
  text-decoration: none;
  color: unset;
  overflow: hidden;
  text-overflow: ellipsis;
}
.templates_project_project ul.projectMeta li.data.timeRegistrationData {
  flex-direction: column;
  padding: 0.5em 0.75em;
}
.templates_project_project ul.projectMeta li.data.timeRegistrationData > .group {
  width: 50%;
  margin-right: 0;
  margin-bottom: 0.5em;
  font-style: italic;
  font-weight: bold;
  padding-right: 0.75em;
  box-sizing: border-box;
}
.templates_project_project ul.projectMeta li.data.timeRegistrationData > table.fields {
  font-size: 0.75em;
}
.templates_project_project ul.projectMeta li.data.timeRegistrationData > table.fields > thead > tr > td {
  font-weight: bold;
}
.templates_project_project ul.projectMeta li.data.timeRegistrationData > table.fields > thead > tr > td:first-child {
  text-align: right;
  padding-right: 1em;
  width: 50%;
}
.templates_project_project ul.projectMeta li.data.timeRegistrationData > table.fields > tbody > tr > td:first-child {
  text-align: right;
  padding-right: 1em;
}
.templates_project_project ul.projectMeta li.data.timeRegistrationData > .subTitle {
  width: 100%;
  margin-top: 0.5em;
}
body.mobile .templates_project_project {
  font-size: 3em;
  padding-bottom: 10vh;
}
body.mobile .templates_project_project button {
  font-size: 0.75em;
}
body.mobile .templates_project_project ul {
  width: 100%;
}
body.mobile .templates_project_project ul li > div.head {
  width: 35%;
  text-overflow: ellipsis;
}
body.mobile .templates_project_project ul li.head > .head {
  display: none;
}
body.mobile .templates_project_project ul li.head > .content {
  width: 100%;
}
body.mobile .templates_project_project ul.projectPage {
  font-size: 0.875em;
}
body.mobile .templates_project_project ul.projectPage > li.buttons {
  flex-direction: column;
}
body.mobile .templates_project_project ul.projectPage > li.buttons > * {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
