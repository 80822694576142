.templates_dashboard_panel {
  display: inline-flex;
  flex-direction: column;
  width: 20vw;
  height: 40vh;
  margin: 2.7vw -7.7vw -1.7vw 8.7vw;
  background-color: #CDCDCD;
  color: #FFFFFF;
}
.templates_dashboard_panel .head {
  display: flex;
  font-size: 2.2em;
  padding: 0.7em 0 0.5em 0.4em;
}
.templates_dashboard_panel .head i {
  line-height: 1.4em;
  padding: 0 0.5em;
}
.templates_dashboard_panel section.controls {
  display: flex;
  font-size: 1.2em;
  flex-direction: column;
  margin: auto 1em 0 2em;
}
