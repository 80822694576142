.base_tableSimple_table.templates_timeRecordings_table_table {
  border-top: 1px solid #DDDDDD;
}
.base_tableSimple_table.templates_timeRecordings_table_table > table > tbody.templates_timeRecordings_table_groupRow tr td {
  padding: 1px 15px;
  font-style: italic;
  font-weight: bold;
  background-color: #FFF7D6;
}
.base_tableSimple_table.templates_timeRecordings_table_table > table > tbody.templates_timeRecordings_table_groupRow tr td > .catering {
  font-weight: normal;
  font-style: normal;
  font-size: 0.875em;
  float: right;
}
.base_tableSimple_table.templates_timeRecordings_table_table > table > tbody.templates_timeRecordings_table_groupRow tr td > .catering > .base_form_fields_toggle > .toggleOption {
  padding-top: 0;
  padding-bottom: 0;
}
.base_tableSimple_table.templates_timeRecordings_table_table > table > tbody.templates_timeRecordings_table_row tr td {
  overflow: hidden;
  text-overflow: ellipsis;
}
