section.main {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
section.main > div {
  display: none;
}
section.main > div:last-child {
  display: flex;
}
* {
  font-family: "Open Sans", Arial, sans-serif;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body.desktop .mobile {
  display: none;
}
body.desktop .desktop {
  display: block;
}
body.mobile {
  font-size: 1vh;
}
body.mobile section.main {
  overflow: auto;
}
body.mobile .phone {
  display: block;
}
body.mobile .desktop {
  display: none;
}
body .appContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
div.emptyTemplate {
  display: none !important;
}
.base_modal_modal {
  z-index: 1000;
}
.base_deciders_confirm_confirm .window,
.base_deciders_confirm_confirm .window.tiny {
  height: 420px;
}
.base_deciders_confirm_confirm .window.footerButtonsStacked .body,
.base_deciders_confirm_confirm .window.tiny.footerButtonsStacked .body {
  height: calc(100% - 240px);
}
.base_deciders_confirm_confirm .window.footerButtonsStacked .footer,
.base_deciders_confirm_confirm .window.tiny.footerButtonsStacked .footer {
  height: 180px;
}
.base_deciders_confirm_confirm .window.footerButtonsStacked .footer > *,
.base_deciders_confirm_confirm .window.tiny.footerButtonsStacked .footer > * {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.base_deciders_confirm_confirm .window.footerButtonsStacked .footer button,
.base_deciders_confirm_confirm .window.tiny.footerButtonsStacked .footer button {
  margin-left: 0;
}
body.mobile .container_errors_errors,
body.mobile .container_base_error_messageDisplay,
body.mobile .base_deciders_confirm_confirm {
  font-size: 2.5em;
}
body.mobile .container_errors_errors .window,
body.mobile .container_base_error_messageDisplay .window,
body.mobile .base_deciders_confirm_confirm .window,
body.mobile .container_errors_errors .window.tiny,
body.mobile .container_base_error_messageDisplay .window.tiny,
body.mobile .base_deciders_confirm_confirm .window.tiny {
  width: calc(100% - 20px);
  left: 10px;
  height: 60%;
  top: 15%;
}
body.mobile .container_errors_errors .window > .footer,
body.mobile .container_base_error_messageDisplay .window > .footer,
body.mobile .base_deciders_confirm_confirm .window > .footer,
body.mobile .container_errors_errors .window.tiny > .footer,
body.mobile .container_base_error_messageDisplay .window.tiny > .footer,
body.mobile .base_deciders_confirm_confirm .window.tiny > .footer {
  font-size: 0.8em;
}
body.mobile .container_base_error_messageDisplay .window,
body.mobile .container_base_error_messageDisplay .window.tiny {
  height: 40%;
}
