.templates_controller_filter {
  padding: 1em;
  box-sizing: border-box;
}
.templates_controller_filter h1 {
  font-size: 1.3em;
  font-weight: normal;
  color: #000000;
  margin: 0.5em 0;
}
.templates_controller_filter .cols .col .field {
  display: flex;
  font-size: 1em;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.templates_controller_filter .cols .col .field > *:not(.fieldIndependent) {
  width: auto !important;
}
.templates_controller_filter .cols .col .field > *:not(.fieldIndependent).base_form_elements_label {
  width: auto !important;
  margin-left: 0.5em;
}
.templates_controller_filter .cols .col .field input,
.templates_controller_filter .cols .col .field textarea,
.templates_controller_filter .cols .col .field select {
  flex: 1;
}
.templates_controller_filter .cols .col .field .base_form_fields_air-datetime-picker {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.templates_controller_filter .cols .col .field .base_form_fields_air-datetime-picker .datepicker-action-icon {
  font-size: 1.8em;
  margin-right: 0.75em;
}
.templates_controller_filter .cols .col .field .base_form_fields_air-datetime-picker .datepicker-action-icon i {
  margin-left: 0;
}
