.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col > section.table {
  margin: 10px -10px 0;
  width: calc(100% + 20px);
}
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field {
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
}
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field > label:not(.fieldIndependent),
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field .base_form_fields_period label {
  width: 100% !important;
  transform: unset;
}
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field > *:not(.fieldIndependent) {
  width: 100% !important;
}
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field.fieldOf_beginTS {
  padding-right: 10px;
}
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field.fieldOf_endTS {
  padding-left: 10px;
}
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field.fieldOf_beginTS,
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field.fieldOf_endTS {
  width: calc(50% - 10px);
}
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field.fieldOf_beginTS input,
.templates_timeRecordings_propertiesForm_propertiesForm > .cols > .col .field.fieldOf_endTS input {
  width: 100%;
}
.base_modal_modal.container_base_form_form.container_templates_timeRecordings_propertiesForm_propertiesForm .window {
  width: 500px;
  left: calc(50% - 250px);
}
.base_modal_modal .window section.body > .base_form_form.templates_timeRecordings_propertiesForm_propertiesForm {
  padding: 10px;
}
body.mobile .base_modal_modal .window section.body > .base_form_form.templates_timeRecordings_propertiesForm_propertiesForm {
  font-size: 0.75em;
}
body.mobile .base_modal_modal.container_base_form_form.container_templates_timeRecordings_propertiesForm_propertiesForm .window {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  top: 5px;
  left: 10px;
}
body.mobile .base_modal_modal.container_base_form_form.container_templates_timeRecordings_propertiesForm_propertiesForm .window .footer {
  font-size: 0.75em;
}
