.templates_treesManagement_actionsForm {
  display: none;
}
.templates_treesManagement_actionsForm h1 {
  font-size: calc(0.35em + 1vmin);
}
.templates_treesManagement_actionsForm button,
.templates_treesManagement_actionsForm button.negative {
  width: 100%;
  float: left;
  clear: left;
  margin: 0 0 0.4em 10px;
  background-color: transparent !important;
  border: none;
  color: #572C00 !important;
  text-align: left;
  font-size: calc(0.31em + 1vmin);
  padding-left: 0;
}
.templates_treesManagement_actionsForm button:hover,
.templates_treesManagement_actionsForm button.negative:hover {
  color: #000000;
  background-color: transparent;
}
