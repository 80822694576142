.container_templates_login_container .window {
  width: 40%;
  height: 40%;
  left: 30%;
  top: 15%;
}
.container_templates_login_container .window section.body .buttons {
  display: none;
}
body.mobile .templates_login_container {
  padding: 2vh;
  margin: 10vh auto;
  font-size: 3vh;
}
body.mobile .templates_login_container .buttons {
  margin-top: 4vh;
  padding-bottom: 0;
}
body.mobile .templates_login_container .buttons button {
  font-size: 2.5vh !important;
}
body.mobile .container_templates_login_container .templates_login_container {
  margin: 0 auto;
}
body.mobile .container_templates_login_container .window {
  width: 90%;
  height: 60%;
  left: 5%;
  top: 15%;
}
