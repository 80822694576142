.templates_shippingOrder_shippingOrder {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.templates_shippingOrder_shippingOrder > * {
  width: 100%;
  padding: 1em 0;
  box-sizing: border-box;
}
.templates_shippingOrder_shippingOrder > section.controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templates_shippingOrder_shippingOrder > section.controls > * {
  margin: 0 1em;
}
.templates_shippingOrder_shippingOrder > section.sheet {
  display: flex;
  flex-direction: column;
  border: 1px solid #CDCDCD;
  box-shadow: 0.25em 0.25em 0.7em #666;
  padding: 1em 0;
  margin: 0 1em;
  width: calc(100% - 2em);
  max-width: 800px;
}
.templates_shippingOrder_shippingOrder > section.sheet > section {
  min-height: 2em;
  padding: 1em 2em;
  box-sizing: border-box;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
  color: #444444;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.header > section {
  margin-bottom: 1em;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.header > section.left {
  margin-right: auto;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.header > section.left h1 {
  font-size: 2.5em;
  margin: 0;
  line-height: 1;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.header > section.left h2 {
  font-size: 0.75em;
  font-style: italic;
  margin: 0 0 0 1em;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.header > section.right .logo {
  width: 200px;
  height: 100px;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.body > table.general {
  margin-bottom: 2em;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.body > table.general > tbody > tr > td:first-child {
  padding-right: 1em;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.footer {
  width: 100%;
  height: calc(120px + 2em);
}
.templates_shippingOrder_shippingOrder > section.sheet > section.footer > table {
  font-size: 0.75em;
  width: 100%;
  height: 100%;
  border-spacing: 0;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.footer > table > tbody > tr > td {
  width: 120px;
  height: 120px;
  padding: 0;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.footer > table > tbody > tr > td a {
  position: relative;
  display: block;
  width: 120px;
  height: 120px;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.footer > table > tbody > tr > td a img {
  width: 100%;
  height: 100%;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.footer > table > tbody > tr > td a i {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 20px;
  margin: -15px 0 0 -15px;
  background-color: #FFFFFF;
  padding: 5px;
  border-radius: 5px;
  overflow: hidden;
  color: #0C789E;
  border: 1px solid #0C789E;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.footer > table > tbody > tr > td:last-child {
  width: auto;
  vertical-align: bottom;
  padding-bottom: 5px;
}
.templates_shippingOrder_shippingOrder > section.sheet > section.footer > table > tbody > tr > td:last-child > * {
  text-align: right;
  margin: 0 0.5em 0 0;
}
body.mobile .templates_shippingOrder_shippingOrder > section.sheet > section {
  padding: 1em;
}
body.mobile .templates_shippingOrder_shippingOrder > section.sheet > section.footer {
  height: auto;
}
body.mobile .templates_shippingOrder_shippingOrder > section.sheet > section.footer > table > tbody > tr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
body.mobile .templates_shippingOrder_shippingOrder > section.sheet > section.footer > table > tbody > tr > td:last-child {
  margin-top: 2em;
  height: auto;
}
body.mobile .templates_shippingOrder_shippingOrder > section.sheet > section.footer > table > tbody > tr > td:last-child > * {
  text-align: center;
}
