.templates_calendar_controller .base_scheduler_task i {
  margin-right: 7px;
  color: #FF9A19;
}
.templates_calendar_controller .base_scheduler_task.lightBlue {
  background-color: #68a5bb;
}
.templates_calendar_controller .base_scheduler_task.green {
  background-color: #007700;
}
.templates_calendar_controller .base_scheduler_task.lightGreen {
  background-color: #3a863a;
}
.templates_calendar_controller .base_scheduler_task.orange {
  background-color: #d97519;
}
.templates_calendar_controller .base_scheduler_task.lightOrange {
  background-color: #FF9A19;
}
