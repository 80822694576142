.templates_base_form_form .cols {
  display: flex;
  justify-content: space-between;
}
.templates_base_form_form .cols .col {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}
.templates_base_form_form .cols .col > * {
  float: unset;
  width: auto;
}
.templates_base_form_form .cols .col:nth-child(n+2) {
  margin-left: 2em;
}
.templates_base_form_form .cols .col .field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.25em;
}
.templates_base_form_form .cols .col .field > label {
  float: unset;
}
.templates_base_form_form .cols .col .field > label:not(.fieldIndependent) {
  font-size: 1em;
}
.templates_base_form_form .cols .col .field > *:not(.fieldIndependent) {
  float: unset;
  width: 80% !important;
}
.templates_base_form_form .cols .col .field > label:not(.fieldIndependent),
.templates_base_form_form .cols .col .field .base_form_fields_period label {
  width: 20% !important;
  transform: unset;
  line-height: normal;
}
.templates_base_form_form .cols .col .field .validationError {
  display: flex;
  min-width: 80%;
  margin-left: 20%;
}
.templates_base_form_form .cols .col .base_form_elements_fieldsetDiv {
  border: none;
  margin: 0;
  padding: 0 0 0.25em 1.5em;
  box-sizing: border-box;
  float: none;
  display: flex;
  flex-direction: column;
}
.templates_base_form_form .cols .col .base_form_elements_fieldsetDiv.nrOfPersonsCollection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.templates_base_form_form .cols .col .base_form_elements_fieldsetDiv.nrOfPersonsCollection .base_form_elements_field {
  display: flex;
  flex-direction: column;
  width: 21%;
}
.templates_base_form_form .cols .col .base_form_elements_fieldsetDiv.nrOfPersonsCollection .base_form_elements_field > label:not(.fieldIndependent) {
  float: none;
  width: auto !important;
  text-align: center;
  color: #000000;
}
.templates_base_form_form .cols .col .base_form_elements_fieldsetDiv.nrOfPersonsCollection .base_form_elements_field.bottomLabel {
  flex-direction: column-reverse;
}
.templates_base_form_form .cols .col .base_form_elements_fieldsetDiv h1 {
  margin: 0.5em 0 1.2em -1.5rem;
  padding: 0 0 0em 0.6em;
  font-size: 1.2em;
  font-weight: normal;
  color: #999999;
  border-bottom: 1px solid #999999;
  text-transform: uppercase;
}
