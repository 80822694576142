.fi {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");font-weight: var(--fa-style, 900);
}
.fi,
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-shipping-order,
.fi.fi-shipping-order-add,
.fi.fi-time-registration,
.fi.fi-time-registration-start,
.fi.fi-timeRecordingBase-shippingOrder,
.fi.fi-timeRecordingBase-timeRegistration,
.fi.fi-shippingOrder-enlarge,
.fi.fi-shippingOrder-reset,
.fi.fi-shippingOrder-shrink,
.fi.fi-timeRegistrationBase-shippingOrder {
  -moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;display: var(--fa-display, inline-block);font-style: normal;font-variant: normal;line-height: 1;text-rendering: auto;
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-shipping-order,
.fi.fi-shipping-order-add,
.fi.fi-time-registration,
.fi.fi-time-registration-start,
.fi.fi-timeRecordingBase-shippingOrder,
.fi.fi-timeRecordingBase-timeRegistration,
.fi.fi-shippingOrder-enlarge,
.fi.fi-shippingOrder-reset,
.fi.fi-shippingOrder-shrink,
.fi.fi-timeRegistrationBase-shippingOrder {
  font-family: 'Font Awesome 6 Free';
}
.fi.fi-trash::before {
  content: "\f2ed";
}
.fi.fi-file::before,
.fi.fi-file-o::before,
.fi.fi-file-text-o::before,
.fi.fi-media-txt::before,
.fi.fi-shipping-order::before {
  content: "\f15c";
}
.fi.fi-calendar::before,
.fi.fi-newsletter-segment-segmentData-fieldType-date::before {
  content: "\f073";
}
.fi.fi-sort-desc::before {
  content: "\f0dd";
}
.fi.fi-sign-out::before {
  content: "\f2f5";
}
.fi.fi-edit-slim::before {
  content: "\f303";
}
.fi.fi-minus-square::before {
  content: "\f146";
}
.fi.fi-exclamation-circle::before,
.fi.fi-error::before {
  content: "\f06a";
}
.fi.fi-shippingOrder-shrink::before {
  content: "\f066";
}
.fi.fi-newsletter-segment-segmentData-fieldType-list::before {
  content: "\f03a";
}
.fi.fi-lock::before {
  content: "\f023";
}
.fi.fi-edit::before,
.fi.fi-edit::before {
  content: "\f044";
}
.fi.fi-share-alt::before,
.fi.fi-assign::before,
.fi.fi-connect-language::before,
.fi.fi-connect-layout::before,
.fi.fi-select::before,
.fi.fi-assign-tags::before,
.fi.fi-upload-tagged::before {
  content: "\f1e0";
}
.fi.fi-users::before {
  content: "\f0c0";
}
.fi.fi-angle-right::before,
.fi.fi-angle-right::before {
  content: "\f105";
}
.fi.fi-folder::before {
  content: "\f07b";
}
.fi.fi-user::before,
.fi.fi-user-o::before {
  content: "\f007";
}
.fi.fi-globe::before,
.fi.fi-website::before {
  content: "\f0ac";
}
.fi.fi-image-new::before,
.fi.fi-file-new::before {
  content: "\f005";
}
.fi.fi-shippingOrder-enlarge::before {
  content: "\f31e";
}
.fi.fi-move::before {
  content: "\f074";
}
.fi.fi-sign-in::before,
.fi.fi-decisionComponent-signIn::before,
.fi.fi-signIn-submit::before {
  content: "\f2f6";
}
.fi.fi-arrow-circle-up::before {
  content: "\f0aa";
}
.fi.fi-window-restore::before {
  content: "\f2d2";
}
.fi.fi-plus-square::before {
  content: "\f0fe";
}
.fi.fi-picture-o::before,
.fi.fi-mediaImage::before {
  content: "\f03e";
}
.fi.fi-shippingOrder-reset::before {
  content: "\f2ea";
}
.fi.fi-check-circle::before {
  content: "\f058";
}
.fi.fi-arrow-circle-down::before,
.fi.fi-lazyload::before {
  content: "\f0ab";
}
.fi.fi-pause::before {
  content: "\f04c";
}
.fi.fi-arrow-circle-left::before {
  content: "\f0a8";
}
.fi.fi-media-doc::before,
.fi.fi-media-docx::before {
  content: "\f1c2";
}
.fi.fi-square-o::before {
  content: "\f0c8";
}
.fi.fi-arrow-circle-right::before {
  content: "\f0a9";
}
.fi.fi-filter::before {
  content: "\f0b0";
}
.fi.fi-newsletter-segment-segmentData-fieldType-boolean::before {
  content: "\3f";
}
.fi.fi-full-screen::before,
.fi.fi-move-all::before {
  content: "\f0b2";
}
.fi.fi-leaf::before {
  content: "\f06c";
}
.fi.fi-file-excel-o::before {
  content: "\f1c3";
}
.fi.fi-move-right::before,
.fi.fi-time-registration-start::before,
.fi.fi-timeRecordingBase-shippingOrder::before,
.fi.fi-timeRecordingBase-timeRegistration::before,
.fi.fi-timeRegistrationBase-shippingOrder::before {
  content: "\f35a";
}
.fi.fi-arrow-right::before,
.fi.fi-arrow-right-stop::before {
  content: "\f061";
}
.fi.fi-circle::before,
.fi.fi-circle-o::before,
.fi.fi-circle::before {
  content: "\f111";
}
.fi.fi-move-up::before {
  content: "\f35b";
}
.fi.fi-file-image-o::before,
.fi.fi-media-svg::before,
.fi.fi-media-jpg::before,
.fi.fi-media-jpeg::before,
.fi.fi-media-png::before,
.fi.fi-media-gif::before {
  content: "\f1c5";
}
.fi.fi-eye::before {
  content: "\f06e";
}
.fi.fi-save::before {
  content: "\f0c7";
}
.fi.fi-sort-asc::before {
  content: "\f0de";
}
.fi.fi-newsletter-segment-segmentData-fieldType-number::before {
  content: "\f163";
}
.fi.fi-arrow-left::before,
.fi.fi-arrow-left-stop::before {
  content: "\f060";
}
.fi.fi-file-pdf-o::before,
.fi.fi-media-pdf::before {
  content: "\f1c1";
}
.fi.fi-tooltip::before {
  content: "\f05a";
}
.fi.fi-mediaImageDetails::before,
.fi.fi-mediaFileDetails::before {
  content: "\f05a";
}
.fi.fi-list-alt::before {
  content: "\f022";
}
.fi.fi-move-left::before {
  content: "\f359";
}
.fi.fi-minus::before {
  content: "\f068";
}
.fi.fi-gear::before,
.fi.fi-cog::before {
  content: "\f013";
}
.fi.fi-newsletter-segment-segmentData-fieldType-time::before,
.fi.fi-time-registration::before {
  content: "\f017";
}
.fi.fi-power-off::before {
  content: "\f011";
}
.fi.fi-download::before {
  content: "\f019";
}
.fi.fi-newsletter-segment-segmentData-fieldType-text::before {
  content: "\f037";
}
.fi.fi-home::before {
  content: "\f015";
}
.fi.fi-upload::before {
  content: "\f093";
}
.fi.fi-move-down::before {
  content: "\f358";
}
.fi.fi-mediaFile::before {
  content: "\f15b";
}
.fi.fi-arrow-down::before,
.fi.fi-arrow-down-stop::before {
  content: "\f063";
}
.fi.fi-arrow-up::before,
.fi.fi-arrow-up-stop::before {
  content: "\f062";
}
.fi.fi-copy::before {
  content: "\f0c5";
}
.fi.fi-plus::before {
  content: "\2b";
}
.fi.fi-times::before,
.fi.fi-close-full-screen::before,
.fi.fi-cross::before {
  content: "\f00d";
}
.fi.fi-map-o::before {
  content: "\f279";
}
.fi.fi-refresh::before {
  content: "\f2f1";
}
.fi.fi-gears::before,
.fi.fi-cogs::before {
  content: "\f085";
}
.fi.fi-chevron-circle-left::before {
  content: "\f137";
}
.fi.fi-hashtag::before {
  content: "\23";
}
.fi.fi-infinity::before {
  content: "\f534";
}
.fi.fi-calendar-monthly::before {
  content: "\f133";
}
.fi.fi-plus-circle::before,
.fi.fi-shipping-order-add::before {
  content: "\f055";
}
.fi.fi-masterComponents::before {
  content: "\f24d";
}
.fi.fi-check::before,
.fi.fi-decisionComponent-submit::before,
.fi.fi-ok-confirm::before,
.fi.fi-check::before {
  content: "\f00c";
}
.fi.fi-angle-left::before,
.fi.fi-angle-left::before {
  content: "\f104";
}
.fi.fi-window-close::before {
  content: "\f410";
}
.fi.fi-exclamation-triangle::before {
  content: "\f071";
}
.fi.fi-database::before {
  content: "\f1c0";
}
.fi.fi-calendar-daily::before {
  content: "\f783";
}
.fi.fi-usd::before {
  content: "\24";
}
.fi.fi-superpowers:before {
  content: "\f2dd";
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-circle-o,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt {
  font-weight: 400;
}
.fi.fi-trash,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-shipping-order,
.fi.fi-shipping-order-add,
.fi.fi-time-registration,
.fi.fi-time-registration-start,
.fi.fi-timeRecordingBase-shippingOrder,
.fi.fi-timeRecordingBase-timeRegistration,
.fi.fi-shippingOrder-enlarge,
.fi.fi-shippingOrder-reset,
.fi.fi-shippingOrder-shrink,
.fi.fi-timeRegistrationBase-shippingOrder {
  font-weight: 900;
}
.fi.fi-arrow-left-stop {
  border-left: 0.125em solid;
}
.fi.fi-arrow-right-stop {
  border-right: 0.125em solid;
}
.fi.fi-arrow-up-stop {
  border-top: 0.125em solid;
}
.fi.fi-arrow-down-stop {
  border-bottom: 0.125em solid;
}
.fi.fi-picture-o {
  font-weight: 400 !important;
}
/* open-sans-regular - greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.72a39de4cefe8de1cbee741d1257cb3b.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.72a39de4cefe8de1cbee741d1257cb3b.eot?#iefix) format('embedded-opentype'),  url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.e7777b3c2bb7ae4d50f3abe9ee4f1eb5.woff2) format('woff2'),  url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.54c038bebb956463f90f71a24f7efa92.woff) format('woff'),  url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.9ccd5e1b1dbea150336d10cec14bc0d6.ttf) format('truetype'),  url(/webpack/images/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-regular.77786a1b5681506a4a372f2b9ae61bc4.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-600 - greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.d8404d8cdf3b92cb043fba1a74ab9b88.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.d8404d8cdf3b92cb043fba1a74ab9b88.eot?#iefix) format('embedded-opentype'),  url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.098c0a7547a49b0ce57658f41c897ecd.woff2) format('woff2'),  url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.c98e15842263b404f6b7533da0523e48.woff) format('woff'),  url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.d31d0aaa8d4384de5eeb85053fa1bc54.ttf) format('truetype'),  url(/webpack/images/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-600.c6b0bad4257e19c40dc29498cf81d5bb.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
/* open-sans-700 - greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.81c1fea707519665682c86ce06bb06d0.eot);
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.81c1fea707519665682c86ce06bb06d0.eot?#iefix) format('embedded-opentype'),  url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.55108f7b45c93a78893b760b940e2d19.woff2) format('woff2'),  url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.357375ac4bf5dda40f834c15f28bba3f.woff) format('woff'),  url(/webpack/fonts/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.fc10ccf11a61bac919b98aba11c990eb.ttf) format('truetype'),  url(/webpack/images/open-sans-v15-greek_cyrillic_latin-ext_greek-ext_latin_cyrillic-ext_vietnamese-700.c537d95794e4189d6e71f5d46702d122.svg#OpenSans) format('svg');
  /* Legacy iOS */
}
.fi {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");font-weight: var(--fa-style, 900);
}
.fi,
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-shipping-order,
.fi.fi-shipping-order-add,
.fi.fi-time-registration,
.fi.fi-time-registration-start,
.fi.fi-timeRecordingBase-shippingOrder,
.fi.fi-timeRecordingBase-timeRegistration,
.fi.fi-shippingOrder-enlarge,
.fi.fi-shippingOrder-reset,
.fi.fi-shippingOrder-shrink,
.fi.fi-timeRegistrationBase-shippingOrder {
  -moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;display: var(--fa-display, inline-block);font-style: normal;font-variant: normal;line-height: 1;text-rendering: auto;
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-trash,
.fi.fi-circle-o,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt,
.fi.fi-shipping-order,
.fi.fi-shipping-order-add,
.fi.fi-time-registration,
.fi.fi-time-registration-start,
.fi.fi-timeRecordingBase-shippingOrder,
.fi.fi-timeRecordingBase-timeRegistration,
.fi.fi-shippingOrder-enlarge,
.fi.fi-shippingOrder-reset,
.fi.fi-shippingOrder-shrink,
.fi.fi-timeRegistrationBase-shippingOrder {
  font-family: 'Font Awesome 6 Free';
}
.fi.fi-trash::before {
  content: "\f2ed";
}
.fi.fi-file::before,
.fi.fi-file-o::before,
.fi.fi-file-text-o::before,
.fi.fi-media-txt::before,
.fi.fi-shipping-order::before {
  content: "\f15c";
}
.fi.fi-calendar::before,
.fi.fi-newsletter-segment-segmentData-fieldType-date::before {
  content: "\f073";
}
.fi.fi-sort-desc::before {
  content: "\f0dd";
}
.fi.fi-sign-out::before {
  content: "\f2f5";
}
.fi.fi-edit-slim::before {
  content: "\f303";
}
.fi.fi-minus-square::before {
  content: "\f146";
}
.fi.fi-exclamation-circle::before,
.fi.fi-error::before {
  content: "\f06a";
}
.fi.fi-shippingOrder-shrink::before {
  content: "\f066";
}
.fi.fi-newsletter-segment-segmentData-fieldType-list::before {
  content: "\f03a";
}
.fi.fi-lock::before {
  content: "\f023";
}
.fi.fi-edit::before,
.fi.fi-edit::before {
  content: "\f044";
}
.fi.fi-share-alt::before,
.fi.fi-assign::before,
.fi.fi-connect-language::before,
.fi.fi-connect-layout::before,
.fi.fi-select::before,
.fi.fi-assign-tags::before,
.fi.fi-upload-tagged::before {
  content: "\f1e0";
}
.fi.fi-users::before {
  content: "\f0c0";
}
.fi.fi-angle-right::before,
.fi.fi-angle-right::before {
  content: "\f105";
}
.fi.fi-folder::before {
  content: "\f07b";
}
.fi.fi-user::before,
.fi.fi-user-o::before {
  content: "\f007";
}
.fi.fi-globe::before,
.fi.fi-website::before {
  content: "\f0ac";
}
.fi.fi-image-new::before,
.fi.fi-file-new::before {
  content: "\f005";
}
.fi.fi-shippingOrder-enlarge::before {
  content: "\f31e";
}
.fi.fi-move::before {
  content: "\f074";
}
.fi.fi-sign-in::before,
.fi.fi-decisionComponent-signIn::before,
.fi.fi-signIn-submit::before {
  content: "\f2f6";
}
.fi.fi-arrow-circle-up::before {
  content: "\f0aa";
}
.fi.fi-window-restore::before {
  content: "\f2d2";
}
.fi.fi-plus-square::before {
  content: "\f0fe";
}
.fi.fi-picture-o::before,
.fi.fi-mediaImage::before {
  content: "\f03e";
}
.fi.fi-shippingOrder-reset::before {
  content: "\f2ea";
}
.fi.fi-check-circle::before {
  content: "\f058";
}
.fi.fi-arrow-circle-down::before,
.fi.fi-lazyload::before {
  content: "\f0ab";
}
.fi.fi-pause::before {
  content: "\f04c";
}
.fi.fi-arrow-circle-left::before {
  content: "\f0a8";
}
.fi.fi-media-doc::before,
.fi.fi-media-docx::before {
  content: "\f1c2";
}
.fi.fi-square-o::before {
  content: "\f0c8";
}
.fi.fi-arrow-circle-right::before {
  content: "\f0a9";
}
.fi.fi-filter::before {
  content: "\f0b0";
}
.fi.fi-newsletter-segment-segmentData-fieldType-boolean::before {
  content: "\3f";
}
.fi.fi-full-screen::before,
.fi.fi-move-all::before {
  content: "\f0b2";
}
.fi.fi-leaf::before {
  content: "\f06c";
}
.fi.fi-file-excel-o::before {
  content: "\f1c3";
}
.fi.fi-move-right::before,
.fi.fi-time-registration-start::before,
.fi.fi-timeRecordingBase-shippingOrder::before,
.fi.fi-timeRecordingBase-timeRegistration::before,
.fi.fi-timeRegistrationBase-shippingOrder::before {
  content: "\f35a";
}
.fi.fi-arrow-right::before,
.fi.fi-arrow-right-stop::before {
  content: "\f061";
}
.fi.fi-circle::before,
.fi.fi-circle-o::before,
.fi.fi-circle::before {
  content: "\f111";
}
.fi.fi-move-up::before {
  content: "\f35b";
}
.fi.fi-file-image-o::before,
.fi.fi-media-svg::before,
.fi.fi-media-jpg::before,
.fi.fi-media-jpeg::before,
.fi.fi-media-png::before,
.fi.fi-media-gif::before {
  content: "\f1c5";
}
.fi.fi-eye::before {
  content: "\f06e";
}
.fi.fi-save::before {
  content: "\f0c7";
}
.fi.fi-sort-asc::before {
  content: "\f0de";
}
.fi.fi-newsletter-segment-segmentData-fieldType-number::before {
  content: "\f163";
}
.fi.fi-arrow-left::before,
.fi.fi-arrow-left-stop::before {
  content: "\f060";
}
.fi.fi-file-pdf-o::before,
.fi.fi-media-pdf::before {
  content: "\f1c1";
}
.fi.fi-tooltip::before {
  content: "\f05a";
}
.fi.fi-mediaImageDetails::before,
.fi.fi-mediaFileDetails::before {
  content: "\f05a";
}
.fi.fi-list-alt::before {
  content: "\f022";
}
.fi.fi-move-left::before {
  content: "\f359";
}
.fi.fi-minus::before {
  content: "\f068";
}
.fi.fi-gear::before,
.fi.fi-cog::before {
  content: "\f013";
}
.fi.fi-newsletter-segment-segmentData-fieldType-time::before,
.fi.fi-time-registration::before {
  content: "\f017";
}
.fi.fi-power-off::before {
  content: "\f011";
}
.fi.fi-download::before {
  content: "\f019";
}
.fi.fi-newsletter-segment-segmentData-fieldType-text::before {
  content: "\f037";
}
.fi.fi-home::before {
  content: "\f015";
}
.fi.fi-upload::before {
  content: "\f093";
}
.fi.fi-move-down::before {
  content: "\f358";
}
.fi.fi-mediaFile::before {
  content: "\f15b";
}
.fi.fi-arrow-down::before,
.fi.fi-arrow-down-stop::before {
  content: "\f063";
}
.fi.fi-arrow-up::before,
.fi.fi-arrow-up-stop::before {
  content: "\f062";
}
.fi.fi-copy::before {
  content: "\f0c5";
}
.fi.fi-plus::before {
  content: "\2b";
}
.fi.fi-times::before,
.fi.fi-close-full-screen::before,
.fi.fi-cross::before {
  content: "\f00d";
}
.fi.fi-map-o::before {
  content: "\f279";
}
.fi.fi-refresh::before {
  content: "\f2f1";
}
.fi.fi-gears::before,
.fi.fi-cogs::before {
  content: "\f085";
}
.fi.fi-chevron-circle-left::before {
  content: "\f137";
}
.fi.fi-hashtag::before {
  content: "\23";
}
.fi.fi-infinity::before {
  content: "\f534";
}
.fi.fi-calendar-monthly::before {
  content: "\f133";
}
.fi.fi-plus-circle::before,
.fi.fi-shipping-order-add::before {
  content: "\f055";
}
.fi.fi-masterComponents::before {
  content: "\f24d";
}
.fi.fi-check::before,
.fi.fi-decisionComponent-submit::before,
.fi.fi-ok-confirm::before,
.fi.fi-check::before {
  content: "\f00c";
}
.fi.fi-angle-left::before,
.fi.fi-angle-left::before {
  content: "\f104";
}
.fi.fi-window-close::before {
  content: "\f410";
}
.fi.fi-exclamation-triangle::before {
  content: "\f071";
}
.fi.fi-database::before {
  content: "\f1c0";
}
.fi.fi-calendar-daily::before {
  content: "\f783";
}
.fi.fi-usd::before {
  content: "\24";
}
.fi.fi-superpowers:before {
  content: "\f2dd";
}
.fi.fi-square-o,
.fi.fi-map-o,
.fi.fi-circle-o,
.fi.fi-copy,
.fi.fi-save,
.fi.fi-error,
.fi.fi-file-o,
.fi.fi-file-excel-o,
.fi.fi-file-text-o,
.fi.fi-file-pdf-o,
.fi.fi-picture-o,
.fi.fi-user-o,
.fi.fi-mediaImage,
.fi.fi-mediaFile,
.fi.fi-media-pdf,
.fi.fi-media-svg,
.fi.fi-media-jpg,
.fi.fi-media-jpeg,
.fi.fi-media-png,
.fi.fi-media-gif,
.fi.fi-media-doc,
.fi.fi-media-docx,
.fi.fi-media-txt {
  font-weight: 400;
}
.fi.fi-trash,
.fi.fi-gear,
.fi.fi-cog,
.fi.fi-gears,
.fi.fi-cogs,
.fi.fi-sign-in,
.fi.fi-sign-out,
.fi.fi-check,
.fi.fi-arrow-left-stop,
.fi.fi-arrow-right-stop,
.fi.fi-arrow-up-stop,
.fi.fi-arrow-down-stop,
.fi.fi-calendar-daily,
.fi.fi-calendar-monthly,
.fi.fi-tooltip,
.fi.fi-shipping-order,
.fi.fi-shipping-order-add,
.fi.fi-time-registration,
.fi.fi-time-registration-start,
.fi.fi-timeRecordingBase-shippingOrder,
.fi.fi-timeRecordingBase-timeRegistration,
.fi.fi-shippingOrder-enlarge,
.fi.fi-shippingOrder-reset,
.fi.fi-shippingOrder-shrink,
.fi.fi-timeRegistrationBase-shippingOrder {
  font-weight: 900;
}
