.templates_dashboard_control {
  display: flex;
  line-height: 1em;
  margin-bottom: 0.5em;
  align-items: baseline;
}
.templates_dashboard_control i {
  margin-right: 0.5em;
}
.templates_dashboard_control:hover {
  cursor: pointer;
  color: #CCC;
}
.templates_dashboard_control:last-child {
  margin-bottom: 2em;
}
